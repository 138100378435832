import React, { ReactElement } from 'react'

import { AdContainer } from './styles'
import { YahooAdsProps } from './types'

export default function YahooAds1({
  yahooads,
  search,
  onClick,
  position,
}: YahooAdsProps): ReactElement {
  const handleClickParent = (id) => {
    onClick(id)
  }

  return (
    <AdContainer>
      {search && (
        <p className="sponsored_text">{`Sponsored Listings For "${search}"`}</p>
      )}
      {yahooads.slice(0, 1).map(({ id, url, title, description, clickUrl }) => (
        <ul key={`${id}`} className={`units top-units ${position}`}>
          <li className="unit row">
            <div className="col-lg-10">
              <a
                href={`${clickUrl}`}
                target="_blank"
                rel="noreferrer"
                onClick={() => handleClickParent(id)}
              >
                <h5 className="title">
                  <div dangerouslySetInnerHTML={{ __html: title }} />
                </h5>
                <p className="host" dangerouslySetInnerHTML={{ __html: url }} />
                <p
                  className="description"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </a>
            </div>
            <div className="col-lg-2">
              <div className="cta">
                <a
                  className="cta_button"
                  href={`${clickUrl}`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleClickParent(id)}
                >
                  <p className="cta_text">SEE IT &gt;</p>
                </a>
              </div>
            </div>
          </li>
        </ul>
      ))}
    </AdContainer>
  )
}
