import React, { ReactElement } from 'react'
import { useQueryParams, StringParam } from 'use-query-params'

import { FaChevronRight } from 'react-icons/fa'
import { Wrapper, AdItem, AdHead, ButtonLink } from './styles'
import { YahooAdsProps } from './types'
import useClient from '@/utils/hooks/use-client'
import useMediaQuery from '@/utils/hooks/use-media-query'
import { IS_MOBILE_QUERY } from '../../styles/global'

export default function SearchYahooAds({
  yahooads,
  onClick,
  firstItem,
}: YahooAdsProps): ReactElement {
  const [{ s }] = useQueryParams({
    s: StringParam,
  })
  const isMobile = useMediaQuery(IS_MOBILE_QUERY)
  const isClient = useClient()
  const handleClickParent = (id) => {
    onClick(id)
  }

  return (
    <Wrapper>
      <AdHead>Sponsored Listings For &quot;{s}&quot;</AdHead>
      {isClient && !yahooads.length ? (
        <div>No ad(s) found</div>
      ) : (
        yahooads.map(({ id, url, title, description, clickUrl, related }) => (
          <AdItem key={`${id}`}>
            <div style={{ width: `100%` }}>
              {isMobile && !firstItem && (
                <div
                  style={{
                    display: `flex`,
                    justifyContent: `end`,
                    alignItems: `center`,
                    marginBottom: `1rem`,
                    width: `100%`,
                  }}
                >
                  <ButtonLink
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleClickParent(id)}
                    href={clickUrl}
                  >
                    SEE IT <FaChevronRight style={{ marginLeft: `0.5rem` }} />
                  </ButtonLink>
                </div>
              )}
              <div className="left-block">
                <a
                  onClick={() => handleClickParent(id)}
                  className="ad-yahoo"
                  title={title}
                  target="_blank"
                  rel="noreferrer"
                  href={clickUrl}
                >
                  <div
                    className="ad-title"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </a>
                <a
                  className="ad-link"
                  href={clickUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{ cursor: `pointer`, display: `block` }}
                  onClick={() => handleClickParent(id)}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: url.toLowerCase() }}
                  />
                </a>
                <a
                  className="ad-description"
                  href={clickUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{ cursor: `pointer`, display: `block` }}
                  onClick={() => handleClickParent(id)}
                >
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </a>
              </div>
              <div className="right-block">
                {!!related.length &&
                  related.map((v) => (
                    <div key={v} className="label">
                      {v}
                    </div>
                  ))}
              </div>
            </div>
            {(!isMobile || firstItem) && (
              <div
                className="btn-link-container"
                style={{
                  display: `flex`,
                  justifyContent: `end`,
                  alignItems: `center`,
                }}
              >
                <ButtonLink
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleClickParent(id)}
                  href={clickUrl}
                >
                  SEE IT <FaChevronRight style={{ marginLeft: `0.5rem` }} />
                </ButtonLink>
              </div>
            )}
          </AdItem>
        ))
      )}
    </Wrapper>
  )
}
